//Counter
.counter-stats {
	display: flex;
	justify-content: center;
	i {
		svg {
			path {
				fill: #051738;
			}
		}
	}
}
.stats {
  text-align: center;
  font-size: 35px;
  font-weight: 700;
  i {
    font-size: 60px;
  }
}
