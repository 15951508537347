//Fil d'ariane
.container_breadcrumb {
    &.devis {
        background-image: url(/images/bg/bg_devis.png);
        background-size: cover;
        height: 357px;
        background-repeat: no-repeat;
        background-position: center;
        .breadcrumb {
            width: fit-content;
            a {
                color: #FFF;
            }
        }
    }
}
.breadcrumb {
    background: none;
    box-shadow: 0 2px 2px -2px $grey;
    margin: 1rem!important;
    padding-left: 0;
    padding-right: 0;
    border-radius: 0;
    a {
        color: $darkblue;
    }
    .breadcrumb-item {
        &.active {
            font-weight: bold;
        }
    }
    .breadcrumb-item + .breadcrumb-item::before {
        font-weight: normal;
        content: ">";
    }
}
@media (max-width: $media_768) {
    .breadcrumb {
        .breadcrumb-item {
            display: block;
            width: 100%;
            padding-left: 0;
            + .breadcrumb-item::before {
                content: "";
                display: none;
            }
        }
    }
}
