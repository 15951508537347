//Mentions Légales + CGV
.otherpage {
    p  {
        text-align: justify;
    }
    a {
        word-break: break-word;
    }
}
//A-propos
.pknous {
    .card {
        svg {
            width: 70px!important;
            height: auto!important;
            display: block;
            margin: auto;
        }
    }
}

//Erreur
#notfound {
    position: relative;
    height: 50vh;
    .notfound {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        max-width: 520px;
        width: 100%;
        line-height: 1.4;
        text-align: center;
        .notfound-404 {
            position: relative;
            height: 200px;
            margin: 0px auto 20px;
            z-index: -1;
            h1 {
                font-size: 236px;
                font-weight: 200;
                margin: 0px;
                color: $darkblue;
                text-transform: uppercase;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
            h2 {
                font-size: 28px;
                font-weight: 400;
                text-transform: uppercase;
                color: $darkblue;
                background: #fff;
                padding: 10px 5px;
                margin: auto;
                display: inline-block;
                position: absolute;
                bottom: 0px;
                left: 0;
                right: 0;
            }
        }
        a {
            display: inline-block;
            width: 100%;
            margin: auto;
        }
    }
}

//Responsive
@media only screen and (max-width: $media_768) {
    .notfound .notfound-404 h1 {
        font-size: 148px;
    }
}
@media only screen and (max-width: 480px) {
    .notfound {
        .notfound-404 {
            height: 148px;
            margin: 0px auto 10px;
            h1 {
                font-size: 86px;
            }
            h2 {
                font-size: 16px;
            }
        }
        a {
            padding: 7px 15px;
            font-size: 14px;
        }
    }
}
