//Questions Réponses
.catqr {
    padding: 20px 0;
    .item {
        text-align: center;
        &:hover {
            a {
                color: $red;
                text-decoration: none;
            }
        }
        .box {
            text-align: center;
            padding: 30px;
            background-color: #fff;
            height: 100%;
        }
        a {
            font-weight: bold;
            margin-top: 28px;
            margin-bottom: 8px;
            color: $darkblue;
            display: block;
            font-size: 1.25rem;
        }
        .description {
            font-size: 15px;
            margin-top: 15px;
            margin-bottom: 20px;
        }
        img {
            max-width: 80px;
        }
    }
}

/////////////
.card-horizontal {
  display: flex;
  flex: 1 1 auto;
}
