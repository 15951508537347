//Box Bandeau

.box {
    height: 470px;
    vertical-align: middle;
    display: table-cell;
    h3 {
        text-transform: uppercase;
        font-weight: bold;
    }
    li {
        font-weight: 500;
        &:not(i) {
            font-size: 1.1rem;
        }
    }
    p {
        font-size: 1rem;
    }
}
//-Responsive
@media (max-width: $media_1200) {
    .box {
        height: 547px;
    }
}
@media (max-width: $media_768) {
    .box {
        height: 100%;
        display: inline-block;
    }
}

//Présentation Catégories
.box-presentation {
    img {
        border-bottom: 4px solid $red;
        height: 192px;
        width: 100%;
        display: block;
    }
    .badge {
        border-radius: 0;
        position: absolute;
        top: 115px;
        padding: 1rem;
        font-weight: 700;
        font-size: 14px;
    }
    a {
        color: $darkblue;
        &:hover {
            text-decoration: none;
            color: $red;
        }
    }
}
@media (max-width: $media_575) {
    .box-presentation {
        img {
            width: 100%;
        }
    }
}


//Articles
.box-article {
    transition: all .4s ease-in-out;
    .image {
        position: relative;
    }
    img {
        height: 141px;
    }
    .badge {
        max-width: 90%;
        white-space: normal;
        border-radius: 0;
        position: absolute;
        bottom: 0;
        right: 0;
        padding: 1rem;
        font-weight: 700;
        font-size: 14px;
    }
    &:hover {
        text-decoration: none;
        transition: all .4s ease-in-out;
        h3 {
            a {
                text-decoration: none;
                color: $red;
            }
        }
        .badge {
            background: $darkblue;
            color: #FFF;
        }
        .divider {
            border-top-color: $darkblue;
        }
    }
}

//Mini box presentation
.listeArticles {
    margin-top: -70px;
    padding-top: 70px;
}
.container_mini_box_pres {
    &.home {
        background: linear-gradient(90deg, $grey 50%, $red 50%);
    }
    //Articles
    .homeactu {
        margin-top: 98px;
    }
}
.mini-box-pres {
    .is-darkblue {
        a {
            color: $darkblue;
            &:hover {
                color: $red;
            }
        }
    }
    .is-red {
        a {
            color: $red;
            &:hover {
                color: $darkblue;
            }
        }
    }
    .is-grey {
        a {
            color: $grey;
            &:hover {
                color: $darkblue;
            }
        }
    }
    .text-white {
        a {
            color: white;
            &:hover {
                color: $darkblue;
            }
        }
    }
    a {
        &:hover {
            text-decoration: none;
        }
    }
    .description {
        font-size: 14px;
    }
    img {
        object-fit: cover;
    }
}
//-Responsive
@media (max-width: $media_991) {
    .container_mini_box_pres {
        &.home {
            background: linear-gradient(180deg, $grey 50%, $red 50%);
        }
        padding-right: 15px!important;
        padding-left: 15px!important;
        padding-bottom: 0!important;
        padding-top: 0!important;
        .col-md {
            padding: 30px !important;
            flex-basis: inherit;
        }
    }
    .homeactu {
        margin-top: auto;
        .mini-box-pres {
            &:first-child {
                margin-top: 1.5rem !important
            }
        }
    }
}
@media (max-width: $media_575) {
    .mini-box-pres {
        img {
            width: 100%;
            margin-bottom: 0.5rem;
        }
        &.media {
            display: inline-block;
        }
    }
}

//Présentation partenaires
.box_partenaire {
    .row {
        > .bg-is-grey {
            border-top: 4px solid $red;
            z-index: 2;
        }
        svg {
            position: absolute;
            background: #eff2f6;
            padding: 0.75rem;
            top: 0%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 100px;
            width: auto;
            /* purgecss start ignore */
            .st0 {
                fill: $red!important;
            }
            .cls-4 {
                fill: $red;
            }
            .cls-3 {
                fill: transparent;
            }
            /* purgecss end ignore */
        }
    }
}
//-Responsive
@media (min-width: $media_991) {
    .box_partenaire {
        max-width: 1300px;
        .row {
            h2 {
                padding: 0 14rem;
            }
        }
    }
}
@media (max-width: $media_991) {
    .box_partenaire .row > .bg-is-grey {
        margin-top: auto;
    }
}

//Formulaire Article Commentaire
/* purgecss start ignore */
#commentaires {
    .slick-prev, .slick-next {
        display: block;
        margin: auto;
        width: 25px;
        height: 25px;
        background: none;
        border: none;
        border-radius: 0;
        outline: none;
        appearance: none;
        &:after {
            content: "";
            position: absolute;
            left: 50%;
            z-index: 11;
            display: block;
            width: 25px;
            height: 25px;
            border-top: 2px solid #fff;
            border-left: 2px solid #fff;
        }
    }
    .slick-prev {
        &:after {
            top: 0;
            transform: rotate(45deg);
        }
    }
    .slick-next {
        margin-top: 1rem;
        &:after {
            bottom: 0;
            transform: rotate(225deg);
        }
    }
    .alert {
        ul {
            margin: auto;
        }
    }
}
//-Responsive
@media (min-width: $media_991) {
    .box_com_article {
        max-width: 1400px;
    }
}
@media (max-width: $media_991) {
    .box_com_article {
        .coms-article {
            &.slick-slider {
                display: block!important;
            }
        }
    }
}
/* purgecss end ignore */

//Box Dossiers
.dossier-card {
    border-radius: 0;
    background: $darkblue;
    box-shadow: 0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05);
    transition: transform 0.3s ease-in-out 0s;
    transform: translateY(0rem);
    padding: 14px 80px 18px 36px;
    min-height: 240px;
    a {
        margin: auto;
        color: #FFF;
        &:hover {
            text-decoration: none;
        }
    }
    margin-bottom: 1rem;
    cursor: pointer;
    height: 100%;
    &:hover{
        transform: translateY(-0.4rem);
    }
    h3{
        font-weight: 600;
    }
    img{
        position: absolute;
        top: 20px;
        right: 15px;
        max-height: 120px;
    }
}
.card-style{
    background-repeat: no-repeat;
    background-position: right;
    background-size: 80px;
    &.depotmarque {
        background-image: url("/images/box/depotmarque.png");
    }
    &.mecrgpd {
        background-image: url("/images/box/mecrgpd.png");
    }
    &.redactioncg {
        background-image: url("/images/box/redactioncg.png");
    }
    &.redactioncontrat {
        background-image: url("/images/box/redactioncontrat.png");
    }
    &.creationsociete {
        background-image: url("/images/box/creationsociete.png");
    }
    &.actujuridique {
        background-image: url("/images/box/actujuridique.png");
    }
}
@media(max-width: $media_1200) {
    .dossier-card {
        height: 286px;
    }
}

@media(max-width: $media_991) {
    .dossier-card{
        height: auto;
    }
}
