//Article : corps d'un article
/* purgecss start ignore */
//Bandeau 
.bandeauArticleDossier {
    background-color: #F7F8FA;

    //Tags
    .tags-article {
        display: flex;
        .is-tag {
            margin-right: 1rem;
            flex-basis: 100%;
            text-align: center;
        }
    }
    
    //Chapeau
    .is-chapeau {
        font-weight: bold;
        display: inline-block;
        width: 100%;
        line-height: 2.1;
        font-size: 18px;
        text-align: justify;
        //Image
        figure {
            margin: 0 0 -1rem 1rem;
            figcaption {
                font-size: 0.75rem;
                font-weight: 400;
                font-style: italic;
            }
        }
    }
}

//Responsive
@media (max-width: $media_768) {
    .bandeauArticleDossier {
        .is-chapeau {
            figure {
                margin: 0 0 1rem;
            }
        }
        .tags-article {
            display: block;
        }
    }

}


.contentarticle {
    text-align: justify;
    article {
        text-align: justify;
    }
    
    //Lien
    a {
        border-bottom: 2px solid $red;
        color: black;
        font-weight: 500;
        &:hover {
            text-decoration: none;
            color: $red;
        }
        &.style4 {
            color: $red;
            border-bottom: none;
            font-weight: 600;
            &:hover {
                color: $darkblue;
            }
        }
    }
    //Corps
    h1,h2,h3,h4,h5,h6 {
        text-align: left;
    }
    h2,h3,h4,h5,h6 {
        margin-top: 45px;
        margin-bottom: 15px;
        line-height: 1.6;
    }
    h1 {
        line-height: 1.35;
        font-size: 34px;
        &.is-dossier {
            font-weight: bold;
            u {
                text-decoration: none;
                box-shadow: inset 0px -12px 0 #eff2f6;
            }
        }
    }
    h2 {
        color: $red;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 27px;
        margin-top: 30px;
    }
    section {
        margin-top: -70px;
        padding-top: 70px;
    }
    h3 {
        color: #051738;
        display: table;
        font-weight: 600;
        box-shadow: inset 0px -10px 0 #eff2f6;
        font-size: 19px;
    }
    .panel-group {
        h3 {
            width: 100%;
            margin: auto;
        }
    }
    h4 {
        color: $red;
        display: inline-block;
        box-shadow: inset 0px -8px 0 $grey;
        font-size: 18px;
        margin-top: 25px;
    }
    h5 {
        font-size: 17px;
        color: #071e4f;
        font-weight: bold;
    }
    p {
        line-height: 2;
    }
    .separator{
        border-bottom: 2px solid $grey;
        margin: 25px 0px;
    }
    blockquote {
        background: $grey;
        font-weight: 400;
        font-style: italic;
        padding: 1.2em 40px;
        margin: 25px 0px;
        line-height:1.6;
        position: relative;
        &::before, &::after {
            color: $quote;
            font-size: 6em;
            position: absolute;
            content: url(/images/svg/blockquote-grey.svg);
            width: 27px;
            fill: $quote;
        }
        &::before {
            left: 0.5rem;
            top: -45px;
            transform: scaleY(-1);
        }
        &::after {
            right: 0.5rem;
            bottom: -45px;
            transform: scaleX(-1);
        }
        p {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    u {
        text-decoration-color: $red;
    }
    b, strong {
        color: $darkblue;
    }
    li {
        margin-bottom: 10px;
        margin-left: 16px;
        line-height: 2;
    }
    ul {
        margin: 0;
        padding: 0rem 1rem;
        padding-right: 0;
        list-style: none;
        > li {
            &::before {
                content: "\2022";
                color: $red;
                font-weight: bold;
                display: inline-block;
                width: 1em;
                margin-left: -1em;
            }
        }
    }
    ol {
        list-style: none;
        counter-reset: my-awesome-counter;
        list-style: none;
        padding-left: 40px;
        > li {
            counter-increment: my-awesome-counter;
            border-bottom: 2px solid $grey;
            padding-bottom: 1.5rem;
            margin: 0 0 0.5rem 0;
            position: relative;
            margin-top: 1rem;
            &::before {
                content: counter(my-awesome-counter);
                color: $red;
                font-weight: bold;
                font-size: 2rem;
                margin-right: 1rem;
                --size: 32px;
                left: calc(-1 * var(--size) - 10px);
                line-height: var(--size);
                width: var(--size);
                height: var(--size);
                text-align: center;
                position: absolute;
                top: 3px;
            }
        }
    }
    //List Horizontale
    .list {
        display: inline-block;
        width: 100%;
        background: $grey;
        span {
            box-shadow: none;
            margin: auto;
            display: block;
            color: #FFF;
            font-size: 18px;
            padding: 1rem 0.5rem;
            font-weight: 500;
        }
        h5 {
            border-bottom: 1px solid $darkgrey;
            padding: 0.5rem 0;
            margin-top: 0;
            margin-bottom: 0.5rem;
        }
        .list-group {
            .list-group-item {
                background: transparent;
                border: none;
                padding: 0.5rem 0rem;
                &:hover {
                    color: $darkblue;
                    font-weight: bold;
                }
            }
        }
    }
    //Card Author
    .card.author {
        font-weight: 400;
        font-style: italic;
        padding: 1rem 0;
        background: #FFF;
        margin: 25px 0px;
        line-height:1.6;
        position: relative;
        border: 0;
        border-radius: 0;
        .card-horizontal {
            padding: 1rem;
            height: 145px;
            &::before {
                color: $red;
                font-size: 7em;
                position: absolute;
                content: url(/images/svg/blockquote.svg);
                left: 176px;
                top: -90px;
                transform: rotate(0deg);
                width: 35px;
            }
            img {
                top: -25px;
                position: relative;
                border: 2px solid $quote;
            }
            .card-text {
                font-style: normal;
                font-weight: 500;
                font-size: 1rem;
            }
            .card-title {
                font-style: normal;
                font-size: 1rem;
            }
        }
    }
}
//Responsive
@media (max-width: 1030px) {
    .containerarticle {
        &.flex-column-reverse {
            flex-direction: column-reverse !important;
        }
        .contentarticle, .navscrollspy {
            max-width: unset;
        }
    }
}
@media (max-width: $media_991) {
    .is-chapeau {
        //Image
        figure {
            img {
                width: 100%;
            }
        }
    }
}
@media (max-width: $media_768) {
    .contentarticle  {
        .card.author {
            .card-horizontal {
                display: block;
                height: 100%;
                &::before {
                    width: 27px;
                    text-align: center;
                    left: 47%;
                }
                img {
                    top: unset;
                    margin: auto;
                    display: block;
                    margin-top: 1rem;
                }
                .card-body {
                    text-align: center;
                }
            }
        }
    }
}
@media (max-width: $media_575) {
    .contentarticle {
        .tags-article {
            display: table;
        }
    }
}
/* purgecss end ignore */
