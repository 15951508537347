//Tags / Badge article
.tags-article {
    text-transform: uppercase;
    .badge, .is-tag {
        font-size: 0.75rem;
        font-weight: 400;
    }
    .is-tag {
        margin-right: 1rem;
        display: inline-block;
        &:last-child {
            margin-right: auto;
        }
        a {
            color: $tag;
            border-bottom: none;
        }
    }
}
