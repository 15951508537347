//Bandeau
.bandeau {
    background: url('/images/bg/bg_bandeau.webp');
    background-position: top;
    background-size: cover;
    height: 520px;
    .box {
        &.chiffres {
            background: rgba(239, 242, 246, 0.92);
        }
        &.devis {
            background: rgba(5, 23, 56, 0.92);
        }
    }
}

//Responsive
@media (max-width: $media_1200) {
    .bandeau {
        height: 100%;
    }
}
@media (max-width: $media_991) {
    .bandeau {
        .pl-0 {
            padding-left: 15px!important;
        }
    }
}
