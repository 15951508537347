//Header
/* purgecss start ignore */
header {
    z-index: 1071!important;
    //background: linear-gradient(90deg, $red  33.33%, rgba(0,0,0,0) 0%), linear-gradient(0deg, $grey 50%, $darkblue 0%);
    .navbar {
        width: 100%;
    }
    .full-header {
        width: 100%;
        margin: auto;
    }
    .hamburger {
        display: none;
    }
    .navbar-light .navbar-toggler-icon.close {
        background-image: url(/images/svg/close.svg);
        width: 30px;
        height: 30px;
        filter: brightness(0) saturate(100%) invert(22%) sepia(64%) saturate(2298%) hue-rotate(316deg) brightness(89%) contrast(104%);
    }
    .container-logo {
        background: $red;
        height: auto;
        .container {
            height: 100%;
            > .row {
                width: 100%;
                display: block;
                text-align: right;
            }
        }
    }
    .top {
        background: $darkblue;
        padding: 0.5rem 20px;
        font-size: 13.5px;
    }
    .container-fluid {
        &.bottom {
            .bottom {
                background: $grey;
                width: 100%;
            }
        }
    }
    
    .navbar-brand {
        svg {
            height: 47px;
            width: auto;
            display: inline-block;
            transition : all 0.3s ease-out;
            float: left;
            margin-right: 0.5rem;
            margin-top: 7px;
            .st0, .st1, .st2 {
                fill: #FFF;
                stroke: #FFF;
            }
        }
        .name-website {
            line-height: 1.7rem;
            font-size: 1.5rem;
            text-align: left;
            margin-top: 3px;
        }
        font-size: 2rem;
        margin-right: 1rem;
        width: max-content;
    }
    .megamenu {
      position: static;
      .dropdown-menu {
        background: none;
        border: none;
        width: 100%;
        .container {
            padding: 0;
            > div {
                width: 100%;
                background: $darkgrey;
            }
        }
        h6 {
            color: $blue;
        }
        ul {
            li {
                .nav-link {
                    padding: 0.5rem 0;
                    color: white;
                    &:hover {
                        color: $blue;
                    }
                }
            }
        }
        img {
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
            width: 100%;
            height: 120px;
        }
      }
    }

    .nav-item {
        > a {
            color: $darkblue !important;
            font-weight: 500;
            transition: all 200ms linear;
            z-index: 2;
        }
        &.dropdown {
            i {
                display: none;
            }
        }
        &.level1 {
            > a {
                position: relative;
                &:not(.notcaret) {
                    &::before{
                        content: '';
                        position: absolute;
                        left: 50%;
                        bottom: -13px;
                        width: 0;
                        height: 0;
                        border-left: 13px solid transparent;
                        border-right: 13px solid transparent;
                        border-top: 13px solid $grey;
                        clear: both;
                        transform: rotate(180deg);
                        z-index: 1;
                        position: absolute;
                        left: 0;
                        right: 0;
                        margin-left: auto;
                        margin-right: auto;
                        opacity: 0;
                    }
                }
                &:not(.devis){
                    &::after {
                        content: '';
                        display: block;
                        width: 0;
                        height: 8px;
                        position: relative;
                        top: -3px;
                        z-index: 1;
                        background: $darkgrey;
                        transition: width .3s;
                    }
                }
                &:hover {
                    &::before {
                        opacity: 1;
                    }
                    &::after {
                        width: 100%;
                    }
                }
                &.active {
                    &::before {
                        opacity: 1;
                    }
                    &::after {
                        width: 100%;
                    }
                }
                &.dropdown-toggle {
                    &::after {
                        border: none;
                        margin-left: 0;
                    }
                }
                > span {
                    position: relative;
                    z-index: 2;
                    top: 4px;
                }
            }
            &.show {
                > a {
                    &.dropdown-toggle {
                        &::after {
                            width: 100%;
                        }
                    }
                }
            }
            &.active {
                > a {
                    &::after {
                        width: 100%;
                    }
                }
            }
            &#devis {
                background: $red;
                transition: background-color 0.35s ease-out;
                padding-left: 0.5rem;
                padding-right: 0.5rem;
                position: absolute;
                right: 381.5px;
                height: 100%;
                a {
                    color: #FFF!important;
                    height: 100%;
                    margin: auto;
                }
                svg {
                    height: 24px;
                    width: auto;
                    display: inline-block;
                    transition: all 0.3s ease-out;
                    margin-right: 8px;
                    margin-top: 3px;
                    filter: brightness(0) saturate(100%) invert(100%) sepia(7%) saturate(126%) hue-rotate(164deg) brightness(118%) contrast(100%);
                }
                &:hover {
                    background-color: $darkblue;
                }
            }
        }
    }
    .dropdown-menu {
        border-color: $grey;
        top: 92%;
        background: $grey;
        .dropdown-item {
            padding: 3px 15px;
            color: $darkblue;
            border-radius: 0;
            transition: all 200ms linear;
            &:hover {
                background: $darkgrey;
            }
            &:active {
                background: $darkblue;
                color: #FFF;
            }
        }
    }
    //MegaMenu
    .widget-container {
        background: linear-gradient(to bottom, rgba(255,255,255,0) 3%, #eff2f6 0%);
        border: none;
        border-bottom: 3px solid $darkblue;
        padding-top: 11px;
        padding-bottom: 0;
        height: 457px;
        .row {
            background-color: #eff2f6;
            height: 100%;
            overflow-y: hidden;
        }
        .title {
            box-shadow: inset 0px -10px 0 #e2e6ed;
            font-size: 1rem;
        }
        .dropdown-item {
            padding-left: 0;
            padding-right: 0;
            font-weight: 500;
            white-space: initial;
            &:hover {
                background: transparent;
                color: $red;
            }
        }
        .with-border-right {
            &::after {
                content: "";
                background-color: #E3E6ED;
                position: absolute;
                top: 0;
                bottom: 0;
                width: 2px;
                height: auto;
                right: -1px;
            }
        }
        .first {
            padding: 0 1.25rem 0 1.25rem; 
            margin-top: 25px;  
            margin-bottom: 25px; 
            [class*="col-"] {
                padding: 0 1.25rem 0 1.25rem; 
            }
        }
        .second {
            padding: 0 1.25rem 0 1.25rem;
            margin-top: 25px; 
            margin-bottom: 25px; 
        }
        .third {
            background-color: #e2e6ed; 
            padding: 44px 4rem;
            display: flex;
            justify-content: center;
            align-items: center;
            .title {
                font-size: 1.7rem!important; 
                line-height: 1.1;
                b {
                    &:first-child {
                        text-align-last: justify; 
                        display: block;
                    }
                    &:last-child {
                        font-weight: 900; 
                        font-size: 2.2rem!important; 
                        display: block; 
                        text-align-last: justify;
                    }
                }
            }
            .text {
                line-height: 2.3; 
                text-align: justify; 
                font-size: 1rem;
            }
        }
    }
}

//Responsive
@media (min-width: 1351px) {
    .navbar-expand-xl .navbar-nav .nav-link {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
    header {
        .top {
            padding: 0.5rem 23px;
        }
    }
}
@media (max-width: 1350px) {
    .navbar-expand-xl .navbar-nav .nav-link {
        padding-left: 0.35rem;
        padding-right: 0.35rem;
    }
    header {
        .top {
            padding: 0.5rem 23px;
        }
    }
}
@media (max-width: 1250px) {
    .navbar-expand-xl .navbar-nav .nav-link {
        padding-left: 0.30rem;
        padding-right: 0.30rem;
    }
    header {
        .top {
            padding: 0.5rem 22px;
        }
    }
}
@media (max-width: 1215px) {
    .navbar-expand-xl .navbar-nav .nav-link {
        padding-left: 0.27rem;
        padding-right: 0.27rem;
    }
    header {
        .top {
            padding: 0.5rem 21px;
        }
    }
}
@media (min-width: 1200px) {
    header {
        .navbar-expand-lg .navbar-collapse {
            padding: 0 0.5rem;
            position: relative;
            top: 4px;
        }
        .navbar-expand-lg .navbar-nav .dropdown-menu {
            margin: auto;
            border-radius: 0;
        }
        .navbar-expand-lg .navbar-nav .nav-link {
            padding-right: 1rem;
            padding-left: 0.5rem;
        }
        .inline-row { display: table-row; }
        .inline-row > div { display: table-cell; }

        /* This will set our heights as we need them */
        .inline-row > div > .row { height: 100%; }
        .inline-row > div .row > div { height: 50%; width: 100%;}
        .container-table {
            display: table;
            width: 100%;
            height: 100%;
        }
        .megamenu {
            .dropdown-menu {
                display: none;
            }
        }
    }
}
@media (max-width: 1199px) {
    header {
        .top {
            font-size: 13px;
            padding: 0.5rem 1rem;
        }
        .bottom {
            padding: 0;
        }
    }
}
@media (max-width: 1199px) {
    header {
        .hamburger {
            background: white;
            display: block;
            border-bottom: 1px solid white;
            button {
                float: right;
            }
        }
        #navbarContent {
            overflow-y: scroll;
            max-height: 480px;
        }
        .navbar-light .navbar-toggler {
            border-color: transparent;
            margin: auto;
            height: 100%;
            outline: none;
        }
        .nav-item {
            &.level1 {
                &:hover::after {
                    width: 0%;
                }
                &.show, &.active {
                    &::after {
                        width: 0%;
                    }
                }
                &#devis {
                    padding: 0.75rem 1rem;
                    text-align: center;
                    margin-top: 1rem;
                    position: relative!important;
                    right: unset!important;
                }
            }
            > a {
                display: inline-block;
                font-weight: bold;
                &.dropdown-toggle::after {
                    content: none;
                }
            }
            &.dropdown {
                i {
                    display: inline-block;
                    float: right;
                    margin-top: 11px;
                    &:hover {
                        color: $red;
                        cursor: pointer;
                    }
                    &.moins {
                        display: none;
                    }
                }
            }
            .dropdown-menu {
                .dropdown-item {
                    white-space: initial;
                }
            }
        }
        .top {
            display: none;
        }
        .bottom {
            width: 100%;
        }
        .navbar-nav {
            padding: 2rem;
        }
        .container-table {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
}
@media (max-width: $media_400) {
    header {
        .navbar-brand {
            margin: auto;
        }
    }
}
/* purgecss end ignore */
