//Card
/* purgecss start ignore */
.card-dark {
	background-color: #EFF2F6;
	.card-body {
		border-radius: 0;
		border: 2px solid $darkblue;
		.card-title {
			margin-top: 0;
			u {
				text-decoration: none;
    			box-shadow: inset 0px -12px 0 #DFE4EC;
			}
		}
		.button {
			float: right;
			font-weight: 700;
		} 
		.select2-container {
			margin-bottom: 15px;
		}
	}
}
/* purgecss end ignore */