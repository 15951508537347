//Accordion
/* purgecss start ignore */
.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

#accordion .panel,
.accordionFAQ .panel {
    border-radius: 0;
    border: 0;
    margin-top: 0px;
    margin-bottom: 0.5rem;
    a.accordion-toggle {
        display: block;
        padding: 0.75rem 1rem;
        text-align: left;
        background: $grey;
        color: #071e4f;
        text-decoration: none;
        font-weight: bold;
        border: none;
        font-size: 1.1rem;
        &.collapsed {
            &:hover, &:focus {
                background-color: $red;
                color: white;
                transition: all 0.2s ease-in;
                &::after {
                    color: white!important;
                }
            }
        }
        &:not(.collapsed) {
            color: white;
            background-color: $red;
            transition: all 0.2s ease-in;
        }
    }
    .panel-heading {
        padding: 0;
        border-radius: 0px;
        text-align: center;
        .accordion-toggle {
            &::after {
                content: '\2212';
                float: right;
                color: white;
                font-weight: lighter;
                transform: rotate(0deg);
                transition: all 0.2s ease-in;
                font-size: 1rem;
            }
            &.collapsed {
                &::after {
                    color: $red;
                    content: '\002b';
                    transition: all 0.2s ease-in;
                    font-size: 1rem;
                }
            }
        }
    }
    .panel-body {
        padding: 1rem;
        a {

        }
    }
}
/* purgecss end ignore */
