//Body
/* purgecss start ignore */
* {
	margin: 0;
	padding: 0;
}

html {
	position: relative;
	min-height: 100%;
	&:focus {
		outline: none;
	}
}

html,
body {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: $poppins;
	background-color: #FFF;
	font-weight: 400;
}

*,
*:before,
*:after {
	box-sizing: inherit;
}

//Width
.w-33 {
	width: 33.33%;
}
.w-60 {
	width: 60%;
}
.w-66 {
	width: 66.66%;
}
.w-70 {
	width: 70%;
}
.w-80 {
	width: 80%;
}
.w-90 {
	width: 90%;
}
//Divider
.divider {
	margin-top: 1rem;
	margin-bottom: 1rem;
	border: 0;
	border-top: 3px solid #d4dbe5;
	&.is-chapeau {
		border-top: 2px solid #d4dbe5;
	}
	&.is-red {
		border-top-color: $red;
	}
	&.is-darkblue {
		border-top-color: $darkblue;
	}
	&.is-grey {
		border-top-color: $grey;
	}
	&.is-darkgrey {
		border-top-color: $darkgrey;
	}
}

//Text transform
.text-none {
	text-transform: none!important;
}

//white-space
.white-space-normal {
	white-space: normal;
}

//Hidden
.hidden {
	display: none;
}
//Objectfit
.objectfitcover {
	object-fit: cover;
}

//Responsive
@media (max-width: $media_991) {
	.introduction {
		.w-50 {
			width: 100%!important;
		}
	}
}

//Center Vertically
.center-vertically {
	position: relative;
	top: 50%;
	transform: translateY(-50%);
}

//Alert Message
.alert {
	p {
		margin: auto;
	}
	border-radius: 0;
	padding: 0.75rem 2rem;
}

//Content Yield
.yield-content {
	margin-top: 84px;
}

//Bg
.bg-light {
	background-color: #FFF!important;
}

//Lien
a {
	&.style1 {
		border-bottom: 2px solid $red;
        font-weight: 500;
        color: $darkblue;
        &:hover {
            color: $red;
            text-decoration: none;
        }
	}
	&.style2 {
		font-weight: 500;
        color: $darkblue;
        &:hover {
            color: $red;
            text-decoration: none;
        }
	}
	&.style3 {
		color: $darkblue;
		padding: 8px 35px 10px;
		transition: all .3s ease-in-out;
		display: inline-block;
		background: transparent;
		font-weight: bold;
		margin: auto;
		i {
		    transition: transform .3s ease-in-out 0s;
		    transform: translateY(0);
		    display: inline-block;
		}
		&:hover {
			color: $red;
			i {
				transform: translateY(.15rem);
			}
		}
	}
	&.style4 {
		font-weight: 500;
        color: $red;
        &:hover {
            color: $darkblue;
            text-decoration: none;
        }
	}
}

@media (min-width: $media_1200) {
	.col-lg-20 {
		max-width: 20%;
	}
}

@media (max-width: $media_1200) {
	.yield-content {
		margin-top: 71px;
	}
}
@media (max-width: $media_575) {
	.yield-content {
		margin-top: 64px;
	}
}

//Tooltip
.tooltip-inner {
	max-width: 200px;
	padding: 0.25rem 0.5rem;
	color: #fff;
	text-align: center;
	background-color: #000;
	border-radius: 0.25rem;
}

//Scroll Ancre non collé
.scrollancre {
	margin-top: -70px;
	padding-top: 70px;
}

//tarteaucitron
.tarteaucitronTitle,
.tarteaucitronDetails{
	background: #000 !important;
}

.pl-30 {
	padding-left: 30px;
}
.pr-30 {
	padding-right: 30px;
}
.font-weight-extra-bold {
	font-weight: 900;
}
/* purgecss end ignore */
