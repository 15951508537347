//Home - Demandez un devis
.demande_devis {
    background-image:linear-gradient(to left, transparent 50%, $darkblue 50%), url('/images/bg/bg_devis.jpg');
    width:100%;
    margin-top: -140px;
    padding-top: 160px;
    overflow: hidden;
    height: 100%;
    background-repeat: inherit;
    background-size: cover;
    background-position: center;
}
//Responsive
@media (max-width: $media_991) {
	.demande_devis {
        background: $darkblue;
    }
}
