//Obtenir un devis 
.getDevis {
    margin-top: -200px;

    @media (min-width: 768px) {
        margin-top: -120px;

        .title {
            position: absolute;
            top: 0;
            z-index: 1;
            transform: translate(-50%, -50%);
            left: 50%;
        }
    }

    .grid-divider {
        padding-top: 65px;
        background-color: #FFF;
        border-top: 4px solid $red;
        overflow-x: hidden; //quickfix to hide divider on left side
        position: relative;
        padding-bottom: 3rem;

        @media (max-width: 767px) {
            padding-top: 20px;
            padding-bottom: 1rem;
        }

        @media (max-width: 450px) {
            img {
                height: 50px;
            }

            span {
                font-size: 13px;
            }
        }

        >[class*="col-"] {
            &:nth-child(n + 2) {
                &::after {
                    content: "";
                    background-color: #e6eaef;
                    position: absolute;
                    top: 0;
                    bottom: 0;

                    @media (max-width: 767px) {
                        width: 100%;
                        height: 1px;
                        left: 0;
                        top: -4px; // quickfix to center line between columns
                    }

                    @media (min-width: 768px) {
                        width: 1px;
                        height: auto;
                        left: -1px; // quickfix to hide divider on left side
                    }
                }
            }
            &:nth-child(2) {
                &::after {
                    @media (max-width: 767px) {
                        display: none;
                    }
                }
            }
        }
    }
}

.customDevis {
    .form-demandez-devis {
        .h4 {
            box-shadow: inset 0px -7px 0 $darkgrey3;
        }

        .radio {
            [type=radio]:not(:checked)+label {
                background-color: #FFF;
            }

            &.is-darkblue {
                label {
                    border-color: #FFF !important;
                }

                &:hover {
                    label {
                        background-color: $darkblue;
                    }
                }
            }
        }

        .form-control {
            &.is-grey {
                background: #FFF;
            }
        }
    }
}
