//Buttons
.button {
	&.active {
		background: $darkblue;
		color: #FFF!important;
	}
	float: left;
	max-width: 100%;
	display: block;
	margin: 1em;
	padding: 0.75em 1em;
	border: none;
	background: none;
	color: inherit;
	vertical-align: middle;
	position: relative;
	z-index: 1;
	-webkit-backface-visibility: hidden;
	-moz-osx-font-smoothing: grayscale;
	letter-spacing: 1px;
	border: 2px solid;
	font-weight: 600;
	&:focus {
		outline: none;
	}
	> span {
		vertical-align: middle;
	}
	overflow: hidden;
	transition: border-color 0.3s, color 0.3s;
	transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 150%;
		height: 100%;
		z-index: -1;
		transform: rotate3d(0, 0, 1, -65deg) translate3d(0, -3em, 0);
		transform-origin: 0% 100%;
		transition: transform 0.3s, opacity 0.3s, background-color 0.3s;
	}
	&:hover {
		text-decoration: none;
		&::before {
			opacity: 1;
			transform: rotate3d(0, 0, 1, 0deg);
			transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
		}
	}
	//Colors
	&.is-darkblue {
		color: $darkblue;
		border-color: $darkblue;
		&::before {
			background: $darkblue;
		}
		&:hover {
			color: #fff;
			border-color: $darkblue;
			&::before {
				background-color: $darkblue;
			}
		}
	}
	&.is-red {
		color: $red;
		border-color: $red;
		&::before {
			background: $red;
		}
		&:hover {
			color: #fff;
			border-color: $red;
			&::before {
				background-color: $red;
			}
		}
	}
	&.is-grey {
		color: $grey;
		border-color: $grey;
		&::before {
			background: $grey;
		}
		&:hover {
			color: $darkblue;
			border-color: $grey;
			&::before {
				background-color: $grey;
			}
		}
	}
}
select {
	&:focus {
		outline: none;
	}
}
@media (max-width: $media_1200) {
	.button {
		padding: 0.5em;
	}
}
//Back to top
.back-to-top {
	position: fixed;
	bottom: 25px;
	right: 25px;
	border-radius: 0;
	display: none;
	z-index: 1;
	padding: 0.5em;
	background: $red;
	color: #FFF;
	border-color: $darkblue;
	opacity: 0.5;
	transition: opacity 0.3s ease-in-out;
	i {
		transition: transform 0.3s ease-in-out 0s;
		transform: translateY(0rem);
		display: block;
	}
	&:hover {
		color: #FFF;
		opacity: 1;
		i {
			transform: translateY(-0.2rem);
			color: #FFF;
		}
	}
}

//Upload
.custom-file-label {
	&::after {
		content: "Cherchez";
	}
}
