//Scrollspy = sidebar
.navscrollspy {
    nav {
        white-space: initial;
        position: sticky;
        top: 100px;
        margin-bottom: 1.5rem;
        ul {
            box-shadow: inset 3px 0 0 $grey;
            list-style: none;
            margin: 0;
            padding: 0;
        }
        li {
            a {
                display: block;
                padding: 0.5rem 1rem;
                color: $darkblue;
                text-decoration: none;
                font-weight: 100;
                border-bottom: none!important;
                &.current, &:hover {
                    color: $red;
                    strong {
                        color: $red;
                    }
                }
                &.current {
                    font-weight: bold;
                    box-shadow: inset 3px 0 0 $red;
                }
                strong {
                    color: $darkblue;
                }
            }
            &::before {
                content: none!important;
            }
        }
    }
    .list-group {
        .list-group-item {
            border: none;
            padding: 0;
            background: none;
            display: flex;
            color: $darkblue;
            font-size: 14.4px;
            font-weight: 500;
            &:hover {
                color: $red;
            }
            i {
                font-size: 11px;
                padding-right: 3px;
                position: relative;
                top: 2px;
            }
        }
    }
}
//Responsive
@media (max-width: $media_991) {
    .navscrollspy {
        nav {
            ul {
                li {
                    text-align: left;
                }
            }
        }
    }
}
