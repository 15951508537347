//Footer
footer {
  position: absolute;
  left: 0;
  bottom: 0;
  height: auto;
  width: 100%;
  font-size: 13px;
  background: #FFF;
  .navbar-brand {
    svg {
      height: 47px;
      width: auto;
      display: inline-block;
      transition : all 0.3s ease-out;
      float: left;
      margin-right: 0.5rem;
      margin-top: 5px;
    }
    .name-website {
      line-height: 1.7rem;
      font-size: 1.5rem;
    }
  }
  ul, .text-copyright {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    a {
      &:hover {
        color: $red;
        text-decoration: none;
      }
    }
  }
  .copyright {
      padding-left: 0;
  }
}
//-Responsive
@media (min-width: 992px) and (max-width: $media_1024) {
    footer .text-copyright, footer ul {
        min-height: 100%;
        display: flex;
        align-items: center;
    }
}
@media (max-width: $media_991) {
  footer {
    text-align: center!important;
    .text-right {
      text-align: center!important;
    }
    .navbar-brand {
      svg {
        width: 100%;
        margin: auto;
        display: block;
      }
      .name-website {
        text-align: center;
        width: 100%;
      }
    }
    .copyright {
        padding-left: 15px;
    }
  }
}
