//Pagination
.pagination {
    border-radius: 0;
}
.page-item {
    &.active {
        .page-link {
            background-color: $red;
        }
    }
}
.page-link {
    background: $darkblue;
    color: #FFF;
    border: none;
}
