//Réseaux sociaux
.container-social {
    width: 0;
    position: relative;
    right: 55px;
    margin-bottom: 1rem;
}
#fixed-social {
    position: sticky;
    top: 90px;
    a {
        color: #fff;
        display: block;
        height: 40px;
        position: relative;
        text-align: center;
        line-height: 40px;
        width: 40px;
        margin-bottom: 1px;
        z-index: 2;
        border: none;
        &:hover {
            i {
                color: $darkblue;
            }
        }
        i {
            color: #aebcce;
            font-size: 20px;
        }
    }
    .facebook {
        position: relative;
        left: -3px;
    }
}
//Responsive
@media (max-width: 1250px) {
    .container-social {
        right: 28px;
    }
}
@media (max-width: $media_1200) {
    .container-social {
        right: 55px;
    }
    #fixed-social {
        top: 180px;
    }
}
@media (max-width: 1100px) {
    .container-social {
        right: 28px;
    }
}
@media (max-width: 1030px) {
    .container-social {
        position: unset;
        margin-bottom: 1rem;
        order: 1;
        margin-top: -1rem;
    }
    #fixed-social {
        position: initial;
        display: flex;
        background: #FFF;
        a {
            i {
                font-size: 16px;
            }
        }
    }
}

//Sur mobile
.responsive_reseaux_sociaux {
    width: 100%;
    margin-bottom: 1rem;
    height: 42px;
    display: block;
    .partage-facebook {
        i {
            position: relative;
            left: -3px;
            top: -2px;
        }
    }
}

.responsive_reseaux_sociaux .menu-open-button, .responsive_reseaux_sociaux .menu-item {
    border-radius: 100% ;
    width: 42px;
    height: 42px;
    position: absolute;
    color: rgba(0,0,0,.4);
    text-align: center;
    transform: translate3d(0, 0, 0);
    transition: transform ease-out 200ms;
    border-radius: 500em!important;
    line-height: 1!important;
    padding: .5em 0em!important;
    box-shadow: 0em 0em 0em 0.1em rgba(0,0,0,.1) inset;
    background: #FFF;
    border-bottom: none;
}

.responsive_reseaux_sociaux .menu-open-button {
    z-index: 2;
    cursor: pointer;
    display: grid;
}

.responsive_reseaux_sociaux .menu-open:checked + .menu-open-button {
    transition-timing-function: linear;
    transition-duration: 200ms;
    transform: scale(0.8, 0.8) translate3d(0, 0, 0);
}

.responsive_reseaux_sociaux .menu-open {
    display: none;
}

.responsive_reseaux_sociaux .hamburger {
    width: 18px;
    height: 2px;
    background: rgba(0,0,0,.4);
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    transition: transform 200ms,
}

.responsive_reseaux_sociaux .menu-item {
    display: grid;
}
.responsive_reseaux_sociaux .menu-item:hover {
    color: rgba(0,0,0,.8);
    text-decoration: none;
}
.responsive_reseaux_sociaux .menu-item:nth-child(3) {
    transition-duration: 180ms;
}
.responsive_reseaux_sociaux .menu-item:nth-child(4) {
    transition-duration: 180ms;
}
.responsive_reseaux_sociaux .menu-item:nth-child(5) {
    transition-duration: 180ms;
}
.responsive_reseaux_sociaux .menu-item:nth-child(6) {
    transition-duration: 180ms;
}

.responsive_reseaux_sociaux .menu-open:checked ~ .menu-item {
    transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}
.responsive_reseaux_sociaux .menu-open:checked ~ .menu-item:nth-child(3) {
    transition-duration: 190ms;
    transform: translate3d(42px, 0, 0);
}
.responsive_reseaux_sociaux .menu-open:checked ~ .menu-item:nth-child(4) {
    transition-duration: 290ms;
    transform: translate3d(84px, 0, 0);
}
.responsive_reseaux_sociaux .menu-open:checked ~ .menu-item:nth-child(5) {
    transition-duration: 390ms;
    transform: translate3d(126px, 0, 0);
}
.responsive_reseaux_sociaux .menu-open:checked ~ .menu-item:nth-child(6) {
    transition-duration: 490ms;
    transform: translate3d(168px, 0, 0);
}

.responsive_reseaux_sociaux .menu-open:checked ~ .menu-item:nth-child(7) {
    transition-duration: 590ms;
    transform: translate3d(210px, 0, 0);
}

.responsive_reseaux_sociaux .menu-open:checked ~ .menu-item:nth-child(8) {
    transition-duration: 690ms;
    transform: translate3d(252px, 0, 0);
}

/*********************************[RESPONSIVE]*********************************/
@media all and (max-width: 1000px) {
    .reseaux_sociaux > a.item {
        font-size: 0.8rem!important;
    }
}

@media all and (max-width: 470px) {
    .reseaux_sociaux > a.item {
        font-size: 0.68rem!important;
    }
}

@media all and (max-width: 400px) {
    /*****[SUJET FORUM]*****/
    .ui.contenu .section_left .list.reseaux_sociaux {
        & a.item {
            display: none;
            &.admin {
                display: initial;
            }
        }

    }
    .responsive_reseaux_sociaux {
        display: block;
    }
}
