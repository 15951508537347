//Pub obtenir un devis
.obtenirdevis {
    p {
        font-size: 16px;
        font-weight: 500;
        text-align: justify;
        margin: 1.5rem auto;
    }
    i.clock {
        font-size: 23px;
        padding-right: 5px;
        display: block;
        text-align: center;
    }
    .icon24 {
        svg {
            fill: $red;
            width: 45px;
            height: 45px;
            margin-right: 10px;
        }
    }
    h2, .h2 {
        font-size: 18.5px;
        span {
            font-weight: 700;
            font-size: 25px;
        }
    }
    .button {
        padding: 0.5em;
    }
    .beneficier {
        margin-bottom: 1.5rem;
        background: linear-gradient(45deg, $red, #FF3679);
        padding: 3px;
        p {
            background: #FFF;
            text-align: center;
            font-size: 16px;
            color: $darkblue;
            font-weight: 500;
            padding: 8px;
            margin: auto;
            span {
                color: #FFF;
                font-weight: 900;
                background: linear-gradient(45deg, $red, #FF3679);
                padding: 0px 5px;
                font-size: 18px;
            }
        }
    }
}

.mobileod {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: $red;
    opacity: 0.95;
    text-align: center;
    padding: 10px;
    z-index: 2;
    a {
        text-decoration: none!important;
        color: #FFF;
        span {
            font-weight: 900;
            background: linear-gradient(45deg, $red, #FF3679);
            padding: 0px 5px;
            margin-right: 0.5rem;
            box-shadow: 0 0 6px hsl(0deg 0% 100%);
        }
    }
}
//Responsive
@media (max-width: $media_1200) {
    .obtenirdevis {
        .media {
            display: block;
            .icon24 {
                margin-bottom: 0.5rem;
                text-align: center;
                svg {
                    margin-right: 0;
                }
            }
        }
        h2 {
            display: inline-grid;
            text-align: center;
            margin: auto;
            width: 100%;
        }
    }
}
@media (max-width: $media_400) {
    .mobileod {
        a {
            span {
                display: block;
                margin: auto;
                margin-bottom: 0.5rem;
            }
        }
    }
}
