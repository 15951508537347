//Formulaire
/* purgecss start ignore */
#demandez-devis {
    margin-top: -70px;
    padding-top: 70px;
}

textarea.form-control {
    height: auto;
    font-family: $poppins;
}

.form-group {
    label {
        font-weight: 500;
    }

    .uploadfile {
        overflow-x: hidden;
    }
}

.form-left {
    .custom-control {
        padding-right: 15px;
    }
}

.selecttheme {
    color: #F0F2F6;

    p {
        font-weight: bold;
        font-size: 1rem;
        max-width: 75%;
        margin: auto;
        color: #B7BCC3;
    }
}

.form-control {
    background: transparent;
    border-radius: 0;
    border: 1px solid #FFF;
    color: #FFF;

    &::placeholder {
        color: #FFF;
        opacity: 1;
        font-style: italic;
        font-size: 0.8rem;
    }

    &.is-red {
        border: 1px solid $red;
        color: $red;

        &::placeholder {
            color: $red;
        }
    }

    &.is-darkblue {
        border: 1px solid $darkblue;
        color: #FFF;
        background: $darkblue;

        &::placeholder {
            color: #FFF;
        }
    }

    &.is-grey {
        border: 1px solid $grey;
        color: $darkblue;
        background: $grey;

        &::placeholder {
            color: $darkblue;
        }

        &:focus {
            box-shadow: 0 0 0 0.2rem $darkblue;
        }
    }
}

//-Radio
.radio {
    display: block;
    margin: 0 auto;
    margin-bottom: 0.5rem;
    //padding-right: 1rem;
    width: fit-content;
    margin-left: 0;

    [type="radio"] {
        //display: none;
        position: fixed;
        opacity: 0;
        pointer-events: none;

        &:not(:checked)+label,
        :not(:checked)+label:hover {
            background-color: transparent;
            color: #FFF;
            border-color: currentColor;
        }
    }

    label {
        color: #fff;
        background-color: $red;
        border-color: $red;
        cursor: pointer;
        display: inline-block;
        font-weight: 500;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        user-select: none;
        border: 1px solid transparent;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        line-height: 1.5;
        border-radius: 5rem;
        transition: all 144ms ease-in-out;
        font-size: 14px;

        &:hover {
            border-color: $red !important;
        }

        &:before {
            float: left;
            content: "";
            display: inline-block;
            height: 13px;
            width: 8px;
            margin-right: 0.6rem;
            margin-top: 3px;
        }

        span {
            transition: transform 0.3s ease-in-out 0s;
            transform: translateX(-7px);
            display: inherit;
        }
    }

    [type="radio"] {
        &:checked+label {
            &::before {
                border-bottom: 3px solid transparent;
                border-right: 3px solid transparent;
                transform: rotate(45deg);
                margin-top: 1px;
                border-left: 0;
                border-top: 0;
                transition: transform 144ms ease-in-out;
            }

            span {
                transform: translateX(0);
            }
        }
    }

    &.is-darkblue {
        label {
            background-color: $darkblue;
            border-color: $darkblue !important;
            color: $darkblue !important;
            &:hover {
                background-color: $darkblue;
                color: #FFF!important;
            }
        }

        [type="radio"] {
            &:checked+label {
                color: #FFF !important;

                &::before {
                    border-bottom-color: $grey;
                    border-right-color: $grey;
                }
            }
        }

    }

    &.is-red {
        label {
            background-color: $red;
            border-color: $red !important;
            color: $red !important;

            &:hover {
                border-color: $red !important;
            }
        }

        [type="radio"] {
            &:checked+label {
                color: #FFF !important;

                &::before {
                    color: #FFF !important;
                }
            }
        }
    }

    &.is-grey {
        label {
            background-color: $grey;
            border-color: $grey !important;
            color: $grey !important;

            &:hover {
                border-color: $grey !important;
            }
        }

        [type="radio"] {
            &:checked+label {
                color: $darkblue !important;

                &::before {
                    color: $darkblue !important;
                }
            }
        }
    }
}

//-Checkbox
.form-row-checkbox {
    margin-left: 20px;

    &.acceptcgu {
        .checkbox {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
}

.checkbox {

    &.is-darkblue,
    &.is-grey {
        .custom-control-input:checked~.custom-control-label::before {
            border-color: $darkblue;
            background-color: $darkblue;
        }
    }

    &.is-red {
        .custom-control-input:checked~.custom-control-label::before {
            border-color: $red;
            background-color: $red;
        }
    }

    .custom-control-input:focus~.custom-control-label::before {
        box-shadow: 0px 0px 0px 3px rgba(5, 23, 56, 0.25);
    }
}

//--Upload
.custom-file-container {
    box-sizing: border-box;
    position: relative;
    display: block;
}

.custom-file-container__custom-file {
    box-sizing: border-box;
    position: relative;
    display: inline-block;
    width: 100%;
    height: calc(2.25rem + 2px);
    margin-bottom: 0;
    margin-top: 5px;

    &:hover {
        cursor: pointer;
    }
}

.custom-file-container__custom-file__custom-file-input {
    box-sizing: border-box;
    min-width: 14rem;
    max-width: 100%;
    height: calc(2.25rem + 2px);
    margin: 0;
    opacity: 0;

    &:focus {
        ~span {
            outline: 1px dotted #212121;
            outline: 5px auto -webkit-focus-ring-color;
        }
    }
}

.custom-file-container__custom-file__custom-file-control {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 5;
    height: calc(2.25rem + 2px);
    padding: .5rem .75rem;
    overflow: hidden;
    line-height: 1.5;
    color: #333;
    user-select: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #c0c0af;
    border-radius: .25rem;
}

.custom-file-container__custom-file__custom-file-control__button {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 6;
    display: block;
    height: calc(2.25rem + 2px);
    padding: .5rem .75rem;
    line-height: 1.25;
    color: #333;
    background-color: #EDEDE8;
    border-left: 1px solid #c0c0af;
    box-sizing: border-box;
}

.custom-file-container__image-preview {
    box-sizing: border-box;
    transition: all 0.2s ease;
    margin-top: 20px;
    margin-bottom: 40px;
    height: 250px;
    width: 100%;
    border-radius: 4px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: #fff;
    overflow: scroll;
}

.custom-file-container__image-multi-preview {
    position: relative;
    box-sizing: border-box;
    transition: all 0.2s ease;
    border-radius: 6px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    float: left;
    margin: 1.858736059%;
    width: 29.615861214%;
    height: 90px;
    box-shadow: 0 4px 10px 0 rgba(51, 51, 51, 0.25);
}

.custom-file-container__image-multi-preview__single-image-clear {
    left: -6px;
    background: #EDEDE8;
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    text-align: center;
    margin-top: -6px;
    box-shadow: 0 4px 10px 0 rgba(51, 51, 51, 0.25);

    &:hover {
        background: #cbcbbd;
        cursor: pointer;
    }
}

.custom-file-container__image-multi-preview__single-image-clear__icon {
    color: #6a6a53;
    display: block;
    margin-top: -2px;
}

//Checkbox
.big-checkbox {
    width: 30px;
    height: 30px;
}

//Required
form label.required:after {
    color: $red;
    content: " *";
}

form .acceptcgu label {
    &:after {
        color: $red;
        content: " *";
        position: relative;
        display: inherit;
        margin-left: 2rem;
    }
}

//Form Demandez un devis

.form-demandez-devis {

    //Fond darkblue
    &.bg-is-darkblue {
        h3 {
            color: #FFF;
            ;
            box-shadow: none;
        }

        .radio.is-darkblue {
            label {
                background-color: $grey;
                border-color: $grey !important;
                color: $darkblue !important;

                &:hover {
                    background-color: $darkblue;
                    border-color: $grey !important;
                    color: $grey !important;
                }
            }

            [type="radio"] {
                &:checked+label {
                    background-color: $darkblue;
                    border-color: $grey !important;
                    color: $grey !important;

                    &::before {
                        color: $grey !important;
                        background: $darkblue;
                    }
                }
            }
        }

        .checkbox.is-darkblue {
            .custom-control-label {
                color: #FFF;
            }

            /*.custom-control-input:checked ~ .custom-control-label::before {
                border-color: #FFF;
            }*/
        }

        .col-form-label {
            color: #FFF;
        }

        .button.is-darkblue {
            &:hover {
                color: $darkblue;

                &::before {
                    background-color: #FFF;
                }
            }

            color: #FFF;
            border-color: #FFF;
        }

        .checkbox {
            .custom-control-input:checked~.custom-control-label::before {
                border-color: $grey;
            }
        }

        a {
            border-bottom: 2px solid $red;
            font-weight: 500;
            color: $grey;

            &:hover {
                color: $red;
                text-decoration: none;
            }
        }
    }

    display: none;

    h3,
    .h3,
    .h4 {
        color: $darkblue;
        display: inline-block;
        box-shadow: inset 0px -7px 0 $grey;
    }

    a {
        border-bottom: 2px solid $red;
        font-weight: 500;
        color: $darkblue;

        &:hover {
            color: $red;
            text-decoration: none;
        }
    }

    .essentiel {
        display: block !important;
    }

    .section-personne,
    .section-prestation {
        display: block;
    }

    .section-personne {
        .form-group {
            display: none;

            &.personne {
                display: block;
            }
        }
    }

    .section-acceptcgu {
        display: block;
    }

    .section-depotmarque,
    .section-redaccg,
    .section-redaccontrat,
    .section-confrgpd,
    .section-creasociete,
    .section-relecdoc,
    .section-autre,
    .section-redacregle,
    .precisezpays,
    .preciseztype,
    .precisezcg,
    .precisezcreasoc,
    .obligachat,
    .precisezjeu,
    .ompimarque,
    .ompimarquenon,
    .autrergpd,
    .societeautre {
        display: none;
    }

    .flexbox {
        display: flex;
    }

    style {
        display: none !important;
    }

    .custom-file-container__image-preview {
        display: none;
    }
}

span {
    &.error {
        margin-bottom: 0.5rem;
        font-weight: 500;
        color: $red;
        display: block;
    }
}

#acceptcgu-error {
    display: block;
}

@media (min-width: $media_991) {
    .selecttheme {
        p {
            font-size: 1.5rem;
        }

        .arrow {
            svg {
                width: 22em !important;
                height: 22em !important;
            }
        }
    }
}

@media (max-width: $media_991) {
    .selecttheme {
        p {
            max-width: 100%;
        }
    }
}

/* purgecss end ignore */
