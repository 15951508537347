//Isotope
#ToolBar {
    display: inline-flex;;
}
//--Search
.content-isotope {
    i {
        &:last-child {
            display: none
        }
    }
}
.grid-uploads {
    .well {
        input {
            border-color: $darkblue;
            color: $darkblue;
            &::placeholder {
                color: $darkblue;
            }
        }
    }
}
.filters-select {
    letter-spacing: 1px;
    border: 2px solid;
    font-weight: 600;
    border-color: $darkblue;
    color: $darkblue;
    width: 170px;
    appearance:none;
    //
    background-image: url('/images/fi-xnsdxl-chevron-solid.png');
    background-repeat: no-repeat;
    background-size: 16px;
    background-position: 95% 50%;
    white-space: nowrap;
    overflow: hidden!important;
    text-overflow: ellipsis;
    max-width: 170px;
}

//Depot de marque liste pays
.filters.depotmarque ul {
    display: flex;
    justify-content: center;
    list-style: none;
    margin: 30px 15px
}
.filters.depotmarque ul li {
    display: inline-block;
    text-align: center;
    margin-right: 12px;
    padding: 0 5px 8px 5px;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
    position: relative;
    margin-bottom: -2px;
    color: $darkblue;
    transition: 0.3s;
    text-transform: uppercase;
    &:hover{
        color: $red;
    }
    &.is-checked{
        color: $red;
    }
    &:last-child{
        margin-right: 0;
    }
}
.grid .grid-item .card {
    background: #EEEEEE;
    text-align: center;
    margin-bottom: 2rem;
    min-height: 400px;
}
@media (max-width: $media_1200) {
    .grid .grid-item .card {
        h2 {
            font-size: 18px;
        }
    }
}

@media (max-width: $media_768) {
    .filters.depotmarque ul {
        display: block;
        li {
            display: block;
            text-align: left;
        }
    }
    #ToolBar {
        display: grid;
        button {
            margin-bottom: 0.5rem!important;
            width: 100%;
        }
        .filters-select  {
            margin-bottom: 0.5rem!important;
            max-width: unset;
            width: 100%;
            text-align-last:center;
        }
    }
}

@media (max-width: $media_575) {
    .grid .grid-item .card {
        min-height: 250px;
    }
}
