//Cares pages A propos
.cares {
    position: relative;
    font-size: 16px;
}
.dock-wrapper {
    width: 100%;
}
.cares-dock {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: auto;
    margin: auto;
    position: relative;
}
.cares-dock-item {
    position: static;
    z-index: 10;
    margin-bottom: 20px;
}
.item-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-transform: uppercase;
    font-size: 1.2em;
    font-weight: bold;
    color: $darkblue;
    &:hover, &:active {
        font-weight: bold;
        color: $darkblue;
    }
    svg {
        height: auto;
        max-height: 100px;
        width: 100px;
        margin-bottom: 10px;
        transition: all 0.25s;
        color: $red;
        fill: $red;
        &.circle {
            display: none;
        }
    }
}
.item-content {
    position: relative;
    text-align: center;
    width: 75%;
    visibility: visible;
    opacity: 1;
    transition: visibility 0s, opacity 0.5s linear;
    margin: auto;
}

//Responsive
@media (min-width: $media_768) {
    .cares {
        height: 440px;
    }
    .cares-dock {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: flex-end;
        max-width: 960px;
        height: 250px;
        margin: auto;
        position: relative;
    }
    .cares-line {
        width: 100%;
        height: 2px;
        background-color: #bebebf;
        position: absolute;
        top: 173px;
    }
    .cares-dock-item {
        margin-bottom: 0;
    }
    .item-icon {
        cursor: pointer;
        margin-bottom: 20px;
        font-weight: 100;
        &:hover svg, &:active svg {
            width: 125px;
            height: 125px;
            color: $red;
            fill: $red;
        }
        svg {
            height: 100px;
            margin-bottom: 20px;
            color: #bebebf;
            fill: #bebebf;
            max-height: unset;
            &.circle {
                display: inline;
                height: 12px;
                width: 12px;
                opacity: 0;
                transition: all 0.25s;
                color: $red;
            }
        }
        &:hover, &:active {
            svg {
                &.circle {
                    opacity: 1;
                }
            }
        }
    }
    .item-content {
        position: absolute;
        top: 300px;
        left: calc(50% - 250px);
        text-align: center;
        width: 500px;
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s, opacity 0.5s linear;
    }
    .item-icon:hover + .item-content,
    .item-icon:active + .item-content {
        visibility: visible;
        opacity: 1;
    }
}
