//Classes couleurs
//-colors
.is-red {
  color: $red;
}
.is-darkblue {
  color: $darkblue;
}
.is-grey {
  color: $grey;
}
.is-black {
    color: #212529;
}
.is-tag {
  color: $tag;
}
//-Background
.bg-is-red {
  background: $red;
}
.bg-is-darkblue {
  background: $darkblue;
  color: #FFF;
}
.bg-is-grey {
  background: $grey;
}
.bg-is-darkgrey2 {
  background: $darkgrey2;
}
